import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentBlock from "../components/contentBlock"

import { rhythm, scale } from "../utils/typography"

class PagePostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <ContentBlock>
          <article
            style={{
              maxWidth: rhythm(30),
              margin: `auto`,
              paddingLeft: rhythm(1),
              paddingRight: rhythm(1),
            }}
          >
            <header>
              <h1
                style={{
                  marginTop: rhythm(1),
                  marginBottom: 0,
                }}
              >
                {post.frontmatter.title}
              </h1>
              <p
                style={{
                  ...scale(-1 / 5),
                  display: `block`,
                  marginBottom: rhythm(1),
                }}
              >
                {/* {post.frontmatter.date} */}
              </p>
            </header>
            <section dangerouslySetInnerHTML={{ __html: post.html }} />
          </article>
        </ContentBlock>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query PagePostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      rawMarkdownBody
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
  }
`

export default PagePostTemplate;
